import React, { useEffect, useState } from "react";
import { Container, Navbar, Offcanvas, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../Header/header.css";

const Header = () => {
  const [open, setOpen] = useState(false);

  // Function to toggle menu for mobile screens only
  const toggleMenu = () => {
    if (window.innerWidth <= 991) {
      setOpen(!open);
    }
  };

  // Close menu if screen is resized above mobile screen width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        setOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  // Sticky header logic
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 120
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  return (
    <header className="header-section">
      <Container>
        <Navbar expand="lg" className="p-0">
          <Navbar.Brand>
            <Nav.Link href="/"> Voyapro Journeys</Nav.Link>
          </Navbar.Brand>

          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
            show={open}
            onHide={() => setOpen(false)} // Ensures the menu can be closed when clicking outside
          >
            <Offcanvas.Header>
              <h1 className="logo">Voyapro Journeys</h1>
              <span className="navbar-toggler ms-auto" onClick={toggleMenu}>
                <i className="bi bi-x-lg"></i>
              </span>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link className="nav-link" href="/" onClick={toggleMenu}>
                  Home
                </Nav.Link>
                <Nav.Link className="nav-link" href="/about-us" onClick={toggleMenu}>
                  ABOUT US
                </Nav.Link>
                <Nav.Link className="nav-link" href="/contact" onClick={toggleMenu}>
                  CONTACT
                </Nav.Link>
                <Nav.Link className="nav-link" href="https://wa.me/+918222974797">
                  +91 8222974797
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>

          <div className="ms-md-4 ms-2">
            <li className="d-inline-block d-lg-none ms-3 toggle_btn">
              <i className={open ? "bi bi-x-lg" : "bi bi-list"} onClick={toggleMenu}></i>
            </li>
          </div>
        </Navbar>
      </Container>
    </header>
  );
};

export default Header;
