import React, { useState, useEffect } from "react";
import Axios from "axios";
import Banner from "../../components/Banner/Banner";
import AdvanceSearch from "../../components/AdvanceSearch/AdvanceSearch";
import Features from "../../components/Features/Features";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import Gallery from "../../components/Gallery/Gallery";
import Cards from "../../components/Cards/Cards";
import PopularCard from "../../components/Cards/PopularCard";

const Home = () => {
  const [destinationsData, setDestinationsData] = useState([]);
  const [popularsData, setPopularsData] = useState([]);

  useEffect(() => {
    // Fetch destinations data
    Axios.get("https://api.voyaprojourneys.com/api/tours")
      .then(response => setDestinationsData(response.data))
      .catch(error => console.error("Error fetching destinations data:", error));

    // Fetch popular activities data
    Axios.get("https://api.voyaprojourneys.com/api/tours/search/getFeaturedTours")
      .then(response => setPopularsData(response.data))
      .catch(error => console.error("Error fetching popular activities data:", error));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  return (
    <>
      <Banner />
      <AdvanceSearch />
      {/* <Features /> */}

      {/* tour section start */}
      <section className="tours_section slick_slider">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading">
                <h1>Top Destination For Your Next Vacation</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Slider {...settings}>
                {destinationsData && destinationsData.length > 0 ? (
                  destinationsData.map((destination, inx) => (
                    <Cards destination={destination} key={inx} />
                  ))
                ) : (
                  <p>No destinations available.</p>
                )}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>

      {/* popular section start */}
      <section className="popular py-5">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading">
                <h1>Popular Activities</h1>
              </div>
            </Col>
          </Row>
          <Row>
            {popularsData && popularsData.length > 0 ? (
              popularsData.map((val, inx) => (
                <Col md={3} sm={6} xs={12} className="mb-5" key={inx}>
                  <PopularCard val={val} />
                </Col>
              ))
            ) : (
              <p>No popular activities available.</p>
            )}
          </Row>
        </Container>
      </section>

      <section className="call_us">
        <Container>
          <Row className="align-items-center">
            <Col md="8">
              <h2 className="heading">
                READY FOR UNFORGETTABLE TRAVEL. REMEMBER US!
              </h2>
              <p className="text">
              A travel agency offers a variety of services, including travel planning and consultation, assisting clients with selecting destinations, itineraries, and vacation packages. They handle flight bookings, ticketing, and hotel reservations, often negotiating exclusive deals. Agencies also provide customized vacation packages, including guided tours, excursions, and special activities like safaris and adventure sports. They cater to corporate travel needs, managing business trips, meetings, and expense tracking, while also organizing group travel for events like family reunions or educational tours. Additional services include cruise bookings, car rentals, and airport transfers. Many agencies assist with visa and passport processing, offer travel insurance, and provide loyalty programs to reward customers. Destination weddings and special event planning are popular services, along with 24/7 customer support to assist with emergencies, flight cancellations, and other travel issues.
              </p>
            </Col>
            <Col md="4" className="text-center mt-3 mt-md-0">
              <a href="/contact" className="secondary_btn bounce">
                Contact Us!
              </a>
            </Col>
          </Row>
        </Container>
        <div className="overlay"></div>
      </section>

      {/* <section className="gallery">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading">
                <h1>Photo Gallery</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Gallery />
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default Home;
