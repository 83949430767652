import React, { useState } from "react";
import axios from "axios";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import "./upload.css";

const UploadTourPage = () => {
  const [tourData, setTourData] = useState({
    title: "",
    city: "",
    address: "",
    desc: "",
    price: 0,
    maxGroupSize: 0,
    featured: false,
  });
  const [imageFile, setImageFile] = useState(null);
  const [deleteId, setDeleteId] = useState("");

  // Handle input changes for form fields
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTourData({
      ...tourData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle image file selection
  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]); // Store selected image
  };

  // Submit new tour data
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", tourData.title);
    formData.append("city", tourData.city);
    formData.append("address", tourData.address);
    formData.append("photo", imageFile);  // Append the image file
    formData.append("desc", tourData.desc);
    formData.append("price", tourData.price);
    formData.append("maxGroupSize", tourData.maxGroupSize);
    formData.append("featured", tourData.featured ? "true" : "false");

    try {
      await axios.post("https://api.voyaprojourneys.com/api/tours", formData);
      alert("Tour uploaded successfully!");
    } catch (error) {
      console.error("Error uploading tour:", error);
      alert("Failed to upload the tour.");
    }
  };

  // Handle deleting a tour
  const handleDelete = async () => {
    try {
      await axios.delete(`https://api.voyaprojourneys.com/api/tours/del/${deleteId}`);
      alert(`${deleteId} Tour deleted successfully!`);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting tour:", error);
      alert("Failed to delete the tour.");
    }
  };

  return (
    <Container className="upload-tour-container">
      <h2 className="upload-title">Upload a New Tour</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formTitle">
              <Form.Label>Tour Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tour title"
                name="title"
                value={tourData.title}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                name="city"
                value={tourData.city}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address"
                name="address"
                value={tourData.address}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formPhoto">
              <Form.Label>Upload Photo</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="formDesc">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter description"
            name="desc"
            value={tourData.desc}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Row>
          <Col md={6}>
            <Form.Group controlId="formPrice">
              <Form.Label>Price ($)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter price"
                name="price"
                value={tourData.price}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formMaxGroupSize">
              <Form.Label>Max Group Size</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter max group size"
                name="maxGroupSize"
                value={tourData.maxGroupSize}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="formFeatured">
          <Form.Check
            type="checkbox"
            label="Featured"
            name="featured"
            checked={tourData.featured}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Upload Tour
        </Button>
      </Form>

      <h3>Delete Tour</h3>
      <Form.Group controlId="formDeleteId">
        <Form.Label>Tour ID to Delete</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Tour ID"
          value={deleteId}
          onChange={(e) => setDeleteId(e.target.value)}
        />
        <Button variant="danger" onClick={handleDelete}>
          Delete Tour
        </Button>
      </Form.Group>
    </Container>
  );
};

export default UploadTourPage;
